import React, { useEffect, useState } from 'react';

import { url } from '../../../data/constant';
import { formatDate } from '../../../utils/dates';
import { Img } from '../img';

import './postList.css';

const PostsList = ({ clubId, route }) => {
  const [items, setItems] = useState([]);

  useEffect(async () => {
    try {
      const request = await fetch(`${url}/post?clubId=${clubId}&status=1`);

      if (request.ok) {
        const posts = await request.json();

        setItems(posts);
      }
    } catch (_) {
      setItems([]);
    }
  }, []);

  function format(date) {
    return formatDate({ date, isoFormat: 'dd {} MMMM, yyy' }).replace('{}', 'de');
  }

  function truncateDescription({ id, description = '' }) {
    if (description.length > 199) {
      return (
        <>
          {`${description.slice(0, 199)}... `}
          <a href={`post?postId=${id}`}>Ver más</a>
        </>
      );
    }

    return description;
  }

  return (
    <>
      <h2 id="publicaciones" className="m-v-32 text-blue post-list-title">Publicaciones</h2>

      {items.map((item, index) => (
        <div className="row" key={String(index)}>
          <div className="card post-card">
            <div className="row">
              <div className="col-6 p-0 post-card-image-div">
                <a href={`post?postId=${item.id}`}>
                  <Img
                    alt=""
                    src={item.img}
                  />
                </a>
              </div>

              <div className="col-6">
                <span className="post-date">
                  {format(+item.date)}
                </span>

                <h3 className="text-blue font-17 m-v-8 font-bold">
                  <a className="text-blue" href={`post?postId=${item.id}`}>{item.title}</a>
                </h3>

                <div>
                  <p className="p-text font-12 post-description">{truncateDescription(item)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export { PostsList };
