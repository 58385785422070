import React from 'react';

import { Img } from '../img';

import { RUBLOS_ID } from '../../../data/constant';
import logoImg from '../../../images/logo.png';

import visionIcon from '../../../images/causa-global-vision.png';
import mitigarHambreIcon from '../../../images/causa-global-hambre.png';
import ambienteIcon from '../../../images/causa-global-medio-ambiente.png';
import diabetesIcon from '../../../images/causa-global-diabetes.png';
import cancerIcon from '../../../images/causa-global-cancer-infantil.png';

import './carrousel.css';

function getIcon({ rubloId }) {
  switch (rubloId) {
    case RUBLOS_ID.vision.id:
      return visionIcon;

    case RUBLOS_ID.hambre.id:
      return mitigarHambreIcon;

    case RUBLOS_ID.ambiente.id:
      return ambienteIcon;

    case RUBLOS_ID.diabetes.id:
      return diabetesIcon;

    case RUBLOS_ID.cancer.id:
      return cancerIcon;

    default:
      return logoImg;
  }
}

const Carrousel = ({ items = [], iconMaxHeiht = 30, title }) => (
  <>
    <h2 className="p-b-8 bold text-blue">{title}</h2>

    <div className="carrousel alings-items-center">
      {items.map((item, index) => (
        <div className="carrousel-card" key={String(index)}>
          <Img
            alt=""
            className="carrousel-card-img"
            src={item.img}
            style={{ backgroundColor: '#f8f8f8f8' }}
          />

          <div className="carousel-card-icon-container">
            <div className="carousel-card-icon">
              <Img alt="" src={getIcon(item)} style={{ maxHeight: iconMaxHeiht }} />
            </div>

            <span className="font-light text-blue p-b-8">{item.title}</span>

            {item.subTitle && <span className="font-bold  p-b-8">{item.subTitle}</span>}
          </div>
        </div>
      ))}
    </div>
  </>
);

export { Carrousel };
