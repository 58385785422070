import React, { isValidElement, useEffect, useState } from 'react';

import { Input } from '../form';
import { ModalContainer } from './ModalContainer';

import { isEmailValid, isOnlyNumber } from '../../../utils';

const AfiliarModal = ({
  disabled = false,
  isSuccess = false,
  onClose = null,
  onSend = null,
}) => {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [job, setJob] = useState('');
  const [age, setAge] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const updateState = (callback) => ({ target }) => {
    const { value } = target;

    if (callback) {
      callback(value);
    }
  };

  function handleClose() {
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  }

  function handleOnAge({ target }) {
    const { value } = target;

    if (!value) {
      setAge('');
      return;
    }

    if (value.length >= 3) {
      setAge(age);
      return;
    }

    const newValue = isOnlyNumber(value) ? value : age;

    setAge(newValue);
  }

  function handleSend() {
    if (onSend) {
      onSend({
        age,
        email,
        job,
        lastName,
        name,
        phone,
      });
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (disabled) {
      return;
    }

    const emailValid = isEmailValid(email);

    if (emailValid && name && lastName && age && email && phone) {
      handleSend();
    }
  }

  return (
    <ModalContainer show handleOutClick={handleClose}>
      <form className="modal-afiliar-form" onSubmit={handleSubmit}>
        <span className="contact-div-title">Cuéntanos sobre usted</span>

        <div className="row">
          <Input id="name" disabled={disabled} containerClass="col sm-12 m-6" onChange={updateState(setName)} requeried placeholder="Nombre(s)" />

          <Input id="last-name" disabled={disabled} containerClass="col sm-12 m-6" onChange={updateState(setLastName)} requeried placeholder="Apellido(s)" />
        </div>

        <div className="row m-t-8">
          <Input disabled={disabled} onChange={updateState(setJob)} containerClass="col sm-12 m-6" id="job" placeholder="Profesión" value={job} />

          <Input disabled={disabled} onChange={handleOnAge} containerClass="col sm-12 m-6" id="age" placeholder="Edad" requeried value={age} />
        </div>

        <span className="contact-div-title m-t-16">¿Cómo podemos contactarte?</span>

        <div className="row">
          <Input id="email" disabled={disabled} containerClass="col sm-12 m-6" onChange={updateState(setEmail)} type="email" requeried placeholder="Correo electrónico" />

          <Input id="phone" disabled={disabled} containerClass="col sm-12 m-6" onChange={updateState(setPhone)} maxLength={10} type="number" requeried placeholder="Télefono móvil" />
        </div>

        <div className="center flex p-t-16">
          <button className="btn btn-blue text-white center" disabled={disabled} type="submit">
            {isSuccess ? 'Correo enviado' : 'Enviar'}
          </button>
        </div>
      </form>
    </ModalContainer>
  );
};

export { AfiliarModal };
