import React, { useEffect, useState } from 'react';

import { url } from '../../../data/constant';
import { formatDate } from '../../../utils/dates';

import './eventList.css';

const EventList = ({ clubId }) => {
  const [items, setItems] = useState([]);

  useEffect(async () => {
    try {
      const request = await fetch(`${url}/event?clubId=${clubId}&status=1`);

      if (request.ok) {
        const posts = await request.json();

        setItems(posts);
      }
    } catch (_) {
      setItems([]);
    }
  }, []);

  function formatM(date) {
    return formatDate({ date, isoFormat: 'MMMM' });
  }

  function formatD(date) {
    return formatDate({ date, isoFormat: 'dd' });
  }

  const onSeeMore = (id) => () => {
    setItems((prev) => prev.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          seeMore: true,
        };
      }

      return item;
    }));
  };

  function truncateDescription({ id, description = '', seeMore }) {
    if (seeMore) {
      return description;
    }

    if (description.length > 199) {
      return (
        <>
          {`${description.slice(0, 199)}... `}
          <button className="event-see-more" type="button" onClick={onSeeMore(id)}>
            Ver más
          </button>
        </>
      );
    }

    return description;
  }

  return (
    <>
      <h2 className="m-v-32 text-blue event-list-title" id="eventos">Próximos Eventos</h2>

      {items.map((item, index) => (
        <div className="row" key={String(index)}>
          <div className="card event-card">
            <div className="row">
              <div className="col-3 m-t-8">
                <div className="bg-blue event-date">
                  <span className="event-date-m">
                    {formatM(item.date)}
                  </span>

                  <span className="event-date-d text-yellow">
                    {formatD(item.date)}
                  </span>
                </div>
              </div>

              <div className="col-9">
                <span className="event-rublo">
                  {item.subTitle}
                </span>

                <h3 className="text-blue font-17 m-v-8 font-bold">{item.title}</h3>

                <p className="p-text event-description">{truncateDescription(item)}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export { EventList };
