import React from 'react';

import './css/ModalContainer.css';

const ModalContainer = ({
  children,
  contentStyle,
  handleOnKeyPress,
  handleOutClick,
  show,
}) => (
  <>
    {show
      && (
        <div className="Modal-wrapper">
          <div
            aria-label="button"
            role="button"
            tabIndex={-1}
            className="Modal-backdrop"
            id="modalOuter"
            onClick={handleOutClick}
            onKeyPress={handleOnKeyPress}
          />

          <div className={'Modal-container-card-content '
            + `${contentStyle || ''}`}
          >
            <>
              <button
                className="modal-close-botton"
                onClick={handleOutClick}
                type="button"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" /></svg>
              </button>

              {children}
            </>
          </div>
        </div>
      )}
  </>
);

export { ModalContainer };
