/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import { Layout } from '../components/layout/layout';
import { Carrousel } from '../components/common/carrousel/Carrousel';
import { Walls } from '../components/common/walls';
import { Img } from '../components/common/img';

import visionIcon from '../images/causa-global-vision.png';
import hambreIcon from '../images/causa-global-hambre.png';
import ambienteIcon from '../images/causa-global-medio-ambiente.png';
import diabetesIcon from '../images/causa-global-diabetes.png';
import cancerIcon from '../images/causa-global-cancer-infantil.png';

import { PostsList } from '../components/common/postsList/PostsList';
import { EventList } from '../components/common/events/EventList';
import { AfiliarModal } from '../components/common/modal';

import { url } from '../data/constant';

import './css/homePage.css';
import { apiPost } from '../utils';

const CATEGORIES = [
  {
    img: visionIcon,
    title: 'Visión',
    href: 'http://lionsclubs.org/es/start-our-global-causes/vision',
  },
  {
    img: hambreIcon,
    title: 'Mitigar el Hambre',
    href: 'https://www.lionsclubs.org/es/start-our-global-causes/hunger',
  },
  {
    img: ambienteIcon,
    title: 'Medio Ambiente',
    href: 'https://www.lionsclubs.org/es/start-our-global-causes/environment',
  },
  {
    img: diabetesIcon,
    title: 'Diabetes',
    href: 'https://www.lionsclubs.org/es/start-our-global-causes/childhood-cancer',
  },
  {
    img: cancerIcon,
    title: 'Cáncer Infantil',
    href: 'https://www.lionsclubs.org/es/start-our-global-causes/childhood-cancer',
  },
];

const URL_IMG = process.env.DEV ? 'http://localhost:3333/api' : 'https://admin.leonesdistritob9.org/api';

const HomePage = ({ context, location }) => {
  const { club, menus, isHome } = context;
  const { facebookLink, twitterLink, instagramLink } = club;
  const [gabineteList, setGabineteList] = useState([]);
  const [rubloList, setRubloList] = useState([]);
  const [sendEmail, setSendEmail] = useState({ isLoading: false, success: false });
  const [showAfiliarModal, setAfiliarModal] = useState(false);

  const disableForm = sendEmail.isLoading || sendEmail.success;

  useEffect(async () => {
    try {
      const request = await fetch(`${url}/gabinete/byClub/${club.id}`);

      if (!request.ok) {
        return;
      }
      const response = await request.json();

      if (response) {
        setGabineteList(response);
      }
    } catch (error) {
      setGabineteList([]);
    }
  }, []);

  useEffect(async () => {
    try {
      const request = await fetch(`${url}/rublo/byClub/${club.id}`);

      if (!request.ok) {
        return;
      }
      const response = await request.json();

      if (response) {
        setRubloList(response);
      }
    } catch (error) {
      setRubloList([]);
    }
  }, []);

  const openLink = (link) => () => {
    window.open(link, '_blank');
  };

  function toggleShowAffiliar() {
    setSendEmail({ isLoading: false, success: false });
    setAfiliarModal((prev) => !prev);
  }

  async function sendAfiliar({
    age,
    email,
    job,
    lastName,
    name,
    phone,
  }) {
    if (disableForm) {
      return;
    }

    const body = {
      age,
      email,
      job,
      lastName,
      name,
      phone,
      clubName: club.name,
    };

    setSendEmail((prev) => ({ ...prev, isLoading: true }));
    const request = await apiPost({ body, url: `correo/afiliarse/${club.id}` });
    setSendEmail((prev) => ({ ...prev, isLoading: false, success: request.isValid }));

    // toggleShowAffiliar();
  }

  function openAfiliarModal(event) {
    event.preventDefault();

    toggleShowAffiliar();
  }

  return (
    <Layout
      facebookLink={facebookLink}
      instagramLink={instagramLink}
      logo={club.logo}
      menu={menus}
      rootPath={club.route || '/'}
      twitterLink={twitterLink}
      whiteTheme
    >
      <>
        {showAfiliarModal && (
          <AfiliarModal
            disabled={disableForm}
            isSuccess={sendEmail.success}
            onClose={toggleShowAffiliar}
            onSend={sendAfiliar}
          />
        )}

        <div className="banner-container">
          <div alt="banner" className="banner-img" style={{ backgroundImage: `url(${URL_IMG}/${club.banner})` }} />

          <div>
            <h1>Club de Leones</h1>
            <h1 className="p-b-8">{club.name}</h1>

            <h3 className="text-white m-l-6 p-b-31 font-light">{club.subtTitle}</h3>

            <div className="header-button row">
              <a
                className="btn btn-blue col-sm-12 p-0 m-r-8 btn-sm text-white"
                href="/"
                rel="noreferrer"
                target="_blank"
                type="button"
                onClick={openAfiliarModal}
              >
                Afiliarse
              </a>

              <a
                className="btn btn-yellow col-sm-12 p-0 btn-sm"
                href="https://www.lionsclubs.org/es/donate"
                target="_blank"
                type="button"
                rel="noreferrer"
              >
                Donar
              </a>
            </div>
          </div>
        </div>

        <div className="header-category-container">
          <div className="header-category-div">
            {CATEGORIES.map((item, index) => (
              <div
                className="header-category m-h-8 primary-color"
                key={String(index)}
                onClick={openLink(item.href)}
                onKeyDown={openLink(item.href)}
                role="button"
                tabIndex={index}
              >
                <div className="header-category-item">
                  <Img alt={item.title} src={item.img} />

                  <span className="text-center text-white">{item.title}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="container about-us">
          <div className="row">
            <div className="img-container col-lg-7 col-s-12">
              <Img alt="nosotros" src={club.aboutUsImg} />
            </div>

            <div className="col-lg-5 col-s-12">
              <h2 className="text-yellow" id="nosotros">Acerca de nosotros</h2>

              <h3>{club.aboutUsTitle}</h3>

              <p className="p-text">{club.aboutUs}</p>

              {/*  Ahorita no joven */}
              {/* <a href="/#" className="btn btn-yellow p-0 btn-sm">Descubre Más</a> */}
            </div>
          </div>
        </div>

        <div className="container vision-mission">
          <div className="row">
            <div className="col-lg-5 col-s-12">
              <h2 className="text-blue m-t-8">Misión</h2>

              <p className="p-text">
                {club.mision}
              </p>

              <h2 className="text-blue">Visión</h2>

              <p className="p-text">
                {club.vision}
              </p>
            </div>

            <div className="img-container col-lg-7 col-s-12">
              <Img alt="mision vision" src={club.misionVisionImg} />
            </div>
          </div>
        </div>

        <div className="container m-t-32 text-center">
          <Carrousel
            title={isHome ? 'Gabinete' : 'Directiva'}
            items={gabineteList.map((item) => ({ ...item, title: item.job }))}
            iconMaxHeiht={40}
          />
        </div>

        <div className="container m-t-32 text-center">
          <Carrousel
            title={isHome ? 'Asesores distritales' : 'Comisiones'}
            items={rubloList.map((item) => ({ ...item, title: item.name, subTitle: item.rublo }))}
          />
        </div>

        <iframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="m-t-32 youtube"
          frameBorder="0"
          src={club.youtubeLink}
          title="YouTube video player"
        />

        <div className="post-container gray">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-6 col-sm-12">
                <PostsList route={club.route} clubId={club.id} id="publicaciones" />
              </div>

              <div className="col-lg-5 col-sm-12">
                <EventList clubId={club.id} id="eventos" />
              </div>
            </div>
          </div>
        </div>

        {club.wallsConfig && club.wallsConfig.wallurl && (
          <>
            <h2 id="redes sociales" className="m-v-32 text-blue font-bold text-center">Redes sociales</h2>

            <Walls
              src="https://walls.io/js/wallsio-widget-1.2.js"
              title={club.wallsConfig.title}
              wallurl={club.wallsConfig.wallurl}
              width="80%"
              height="300"
            />
          </>
        )}
      </>
    </Layout>
  );
};

export default HomePage;
